import { ClusterData, LoginForm } from "../types"
import { HttpClient } from "../../helpers/api"
import { createReducer } from "../../helpers/store"
import { validateForm } from "./helpers"
import { redirectToCluster } from "../helpers"
import { cluster } from "d3"

const DEFAULT_STATE = {
  email: "",
  password: "",
  cluster: {
    label: "",
    value: ""
  },
  formErrors: {},
  clusters: [],
  isLoading: false
}

const authDuck = createReducer({
  name: "authorization",
  initialState: DEFAULT_STATE,
  reducers: {
    reset: () => DEFAULT_STATE,
    changeField: (state, payload: { field: keyof typeof DEFAULT_STATE; value: unknown }) => ({
      ...state,
      [payload.field]: payload.value,
      formErrors: { ...state.formErrors, [payload.field]: "" }
    }),
    setErrors: (state, payload: { error: "invalid_email" | "invalid_password" | "invalid_cluster" }) => {
      const isEmailError = payload.error === "invalid_email"
      const isClusterError = payload.error === "invalid_cluster"

      return {
        ...state,
        formErrors: {
          ...state.formErrors,
          email: isEmailError ? "Email address is not found. Please try another or contact your admin." : "",
          password: !isEmailError ? "This password is invalid. Please try again or contact Voiso support." : "",
          cluster: isClusterError ? "This field cannot be empty." : ""
        }
      }
    },
    fetchClustersDone: (state, clusterData: Array<ClusterData>) => {
      return {
        ...state,
        clusters: clusterData.map((cluster) => ({ label: cluster.cluster, value: cluster.url }))
      }
    },
    fetchClustersFailed: (state) => ({
      ...state,
      isLoading: false
    }),
    submitForm: (state, _: LoginForm) => {
      const formErrors = validateForm(state.email, state.password)

      return {
        ...state,
        formErrors
      }
    },
    fetchAuthUsersStarted: (state, _: LoginForm) => ({ ...state, isLoading: true }),
    changeLoading: (state, payload) => ({ ...state, isLoading: payload })
  }
})

export const authActions = authDuck.actions

authDuck.effects.case(authActions.submitForm, async (store, { payload }) => {
  if (Object.keys(store.getState().authorization.formErrors).length > 0) return

  store.dispatch(authActions.fetchAuthUsersStarted(payload))
})

authDuck.effects.case(authActions.fetchAuthUsersStarted, async (store, { payload }) => {
  try {
    const availableClusters = await HttpClient.post("/api/session.json", payload)

    store.dispatch(authActions.fetchClustersDone(availableClusters))
  } catch (e) {
    store.dispatch(authActions.fetchClustersFailed())
    store.dispatch(authActions.setErrors(e))
  }
})

authDuck.effects.case(authActions.fetchClustersDone, async (store, { payload }) => {
  const isMultipleClusters = payload.length > 1

  if (!isMultipleClusters) {
    redirectToCluster(payload[0].url)

    return
  }

  store.dispatch(authActions.changeLoading(false))
})

export const effects = authDuck.effects

export default authDuck.reducer
