import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"

import { Link, palette, Typography } from "@callcenter/voiso-design-system"

import voisoLogo from "images/voisoNew/navbar/logo.svg"
import { authActions, RootState } from "./ducks"
import { ClusterData } from "./types"
import { LoginForm, SelectionForm } from "./modules"

import styles from "./login.module.scss"

const Login = () => {
  const dispatch = useDispatch()
  const isSelectionView =
    useSelector<RootState, Array<ClusterData>>(({ authorization }) => authorization.clusters).length > 1

  useEffect(() => () => dispatch(authActions.reset()), [])

  return (
    <div className={styles.page}>
      <div className={styles.loginContainer}>
        <Link href="https://voiso.com" className={styles.voisoLink}>
          <img alt="Voiso" src={voisoLogo} className={styles.voisoLogo} />
        </Link>

        <div className={styles.formContainer}>
          <div className={styles.formHeader}>
            <Typography.Heading3>Login</Typography.Heading3>
            <Typography.Body2 color={palette.gray8}>
              {isSelectionView
                ? "Select the Voiso cluster hosting your contact center"
                : "Login with your Voiso ID to access your account"}
            </Typography.Body2>
          </div>

          {isSelectionView ? <SelectionForm /> : <LoginForm />}
        </div>

        {!isSelectionView && (
          <div className={styles.formFooter}>
            <Typography.Body2 color={palette.gray7}>
              Forgot password? Contact <Link href="mailto:support@voiso.com">support@voiso.com</Link>
            </Typography.Body2>
            <Typography.Body2 color={palette.gray7}>
              Don’t have account yet?{" "}
              <Link href="https://voiso.com/request-a-demo">Contact us to get a free trial</Link>
            </Typography.Body2>
          </div>
        )}
      </div>
    </div>
  )
}

export { Login }
