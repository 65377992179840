import React from "react"
import { Provider } from "react-redux"

import { Login } from "./login"
import { store } from "./ducks"

export const App = () => (
  <Provider store={store}>
    <Login />
  </Provider>
)
