import React from "react"
import { render } from "react-dom"

import { App } from "./app"

const MARKER = "[data-app=login]"

export const init = () => {
  const el = document.querySelector(MARKER)

  if (el) {
    render(<App />, el)
  }
}
