import { useDispatch } from "react-redux"
import { AnyAction, Middleware, Store } from "redux"

export const createReducer = <
  S extends unknown,
  R extends Record<string, (state: S, payload?: any) => S>,
  AA extends { [K in keyof R]: ((p?: Parameters<R[K]>[1]) => AnyAction) & { type: string } }
>({
  name,
  initialState,
  reducers
}: {
  name: string
  reducers: R
  initialState: S
}): {
  name: string
  actions: AA
  reducer: (state: S | undefined, action: AnyAction) => S
  effects: Middleware & {
    case: (a: { type: string }, cb: (s: Store<any>, a: AnyAction & { payload: any }) => void) => void
  }
} => {
  const createType = (key: string) => `${name}/${key}`

  const actions = (Object.fromEntries(
    Object.entries(reducers).map(([key]) => {
      const action = (payload) => ({ type: createType(key), payload })

      action.type = key

      return [key, action]
    }, {})
  ) as unknown) as AA

  const _reducers = Object.fromEntries(Object.entries(reducers).map(([key, value]) => [createType(key), value]))

  const reducer = (state: S = initialState, action: AnyAction) => {
    const handler = _reducers[action.type]

    if (handler) {
      return handler(state, action.payload)
    }
    return state
  }

  const _effects = {}

  const effects = ((state) => (next) => (action: AnyAction) => {
    next(action)

    const handler = _effects[action.type]

    if (handler) {
      handler(state, action)
    }
  }) as Middleware & {
    case: (a: { type: string }, cb: (s: Store<any>, a: AnyAction & { payload: any }) => void) => void
  }

  effects.case = <RootState>(
    action: { type: string },
    cb: (s: Store<RootState>, a: AnyAction & { payload: any }) => void
  ) => {
    _effects[createType(action.type)] = cb
  }

  return {
    actions,
    reducer,
    name,
    effects
  }
}

export const useActions = <A extends Record<string, (p) => AnyAction>>(
  actions: A
): Record<keyof A, (props: Parameters<A[keyof A]>[0]) => void> => {
  const dispatch = useDispatch()

  return (Object.fromEntries(
    Object.entries(actions).map(([key, value]) => {
      return [
        key,
        (props: Parameters<typeof value>) => {
          dispatch(value(props))
        }
      ]
    })
  ) as unknown) as Record<keyof A, (props: Parameters<A[keyof A]>[0]) => void>
}
