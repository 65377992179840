import { Button, Select, SelectDropDown } from "@callcenter/voiso-design-system"
import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { RootState, authActions } from "../ducks"
import { SelectOption } from "../types"
import { redirectToCluster } from "../helpers"

export const SelectionForm = () => {
  const dispatch = useDispatch()

  const cluster = useSelector<RootState, SelectOption>(({ authorization }) => authorization.cluster)
  const clusters = useSelector<RootState, Array<SelectOption>>(({ authorization }) => authorization.clusters)
  const formErrors = useSelector<RootState, Record<string, string>>(({ authorization }) => authorization.formErrors)

  return (
    <>
      <SelectDropDown
        kind={Select.kind.outline}
        value={cluster}
        placeholder="Select cluster"
        options={clusters}
        onSelect={(option) => dispatch(authActions.changeField({ field: "cluster", value: option }))}
        isError={Boolean(formErrors?.cluster)}
        hint={formErrors?.cluster}
      />

      <Button
        onClick={() => {
          if (!cluster.value.length) {
            dispatch(authActions.setErrors({ error: "invalid_cluster" }))

            return
          }

          redirectToCluster(cluster.value)
        }}
      >
        Confirm
      </Button>
    </>
  )
}
