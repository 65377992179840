import React from "react"
import { useDispatch, useSelector } from "react-redux"

import { RootState, authActions } from "../ducks"

import styles from "../login.module.scss"
import { Button, Input, Spinner, palette } from "@callcenter/voiso-design-system"

export const LoginForm = () => {
  const dispatch = useDispatch()

  const email = useSelector<RootState, string>(({ authorization }) => authorization.email)
  const password = useSelector<RootState, string>(({ authorization }) => authorization.password)
  const isLoading = useSelector<RootState, boolean>(({ authorization }) => authorization.isLoading)
  const formErrors = useSelector<RootState, Record<string, string>>(({ authorization }) => authorization.formErrors)
  return (
    <>
      <div className={styles.form}>
        <Input.Base
          value={email}
          onChange={(value) => dispatch(authActions.changeField({ field: "email", value }))}
          label="Email"
          placeholder="Enter email address"
          isError={Boolean(formErrors?.email)}
          hint={formErrors?.email}
        />
        <Input.Password
          value={password}
          onChange={(value) => dispatch(authActions.changeField({ field: "password", value }))}
          label="Password"
          placeholder="Enter password"
          isError={Boolean(formErrors?.password)}
          hint={formErrors?.password}
        />
      </div>

      {
        <Button onClick={() => dispatch(authActions.submitForm({ email, password }))}>
          {isLoading ? <Spinner size={16} color={palette.blue6} /> : "Login"}
        </Button>
      }
    </>
  )
}
