import { EMPTY_FIELD_ERROR, EMAIL_FORMAT_ERROR, EMAIL_REGEXP } from "./constants"

const validateEmail = (stringToValidate: string): boolean => {
  const valid = EMAIL_REGEXP.test(stringToValidate)

  return !valid && stringToValidate.length > 0
}

export const validateForm = (email: string, password: string) => {
  const errors: Record<string, string> = {}

  console.log(email, password, !email, !password)

  if (!email) errors.email = EMPTY_FIELD_ERROR
  if (!password) errors.password = EMPTY_FIELD_ERROR
  if (validateEmail(email)) errors.email = EMAIL_FORMAT_ERROR

  return errors
}
