const request = (url: string, props: RequestInit) =>
  fetch(url, props).then(async (response) => {
    if (response.ok) {
      return response.json().catch((err) => null)
    } else {
      throw await response.json()
    }
  })

const toQueryParams = (params: Record<string, any>): string =>
  "?" +
  Object.entries(params || {})
    .reduce((acc, [key, value]) => {
      acc.append(key, String(value))

      return acc
    }, new URLSearchParams())
    .toString()

export const HttpClient = {
  get: (
    url: string,
    options?: {
      params?: Record<string, any>
      headers?: Record<string, string>
    }
  ) => {
    const params = options?.params ? toQueryParams(options.params) : ""
    return request(url + params, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        ...(options?.headers || {})
      }
    })
  },
  delete: (
    url: string,
    options?: {
      params?: Record<string, any>
      headers?: Record<string, any>
    }
  ) => {
    const params = options?.params ? toQueryParams(options.params) : ""
    return request(url + params, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        ...(options?.headers || {})
      }
    })
  },
  post: (
    url: string,
    body?: any,
    options?: {
      params?: Record<string, any>
      headers?: Record<string, any>
    }
  ) => {
    const params = options?.params ? toQueryParams(options.params) : ""
    return request(url + params, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...(options?.headers || {})
      }
    })
  },
  put: (
    url: string,
    body?: any,
    options?: {
      params?: Record<string, any>
      headers?: Record<string, any>
    }
  ) => {
    const params = options?.params ? toQueryParams(options.params) : ""
    return request(url + params, {
      method: "PUT",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...(options?.headers || {})
      }
    })
  },
  patch: (
    url: string,
    body?: any,
    options?: {
      params?: Record<string, any>
      headers?: Record<string, any>
    }
  ) => {
    const params = options?.params ? toQueryParams(options.params) : ""
    return request(url + params, {
      method: "PATCH",
      body: JSON.stringify(body),
      headers: {
        "Content-Type": "application/json",
        ...(options?.headers || {})
      }
    })
  }
}
